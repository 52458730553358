
import { defineComponent } from 'vue'

import { Form, Field, ErrorMessage } from 'vee-validate'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import BarriersCard from '@/components/molecules/card/BarriersCard.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
// import AddBarrierPopup from '@/components/molecules/AddBarrierPopup.vue'
import { Vue3Lottie } from 'vue3-lottie'
import authHeader from '@/services/auth-header'
import Select from '@/components/atoms/CustomSelect.vue'
import Info from '@/assets/svg/info.svg?inline'

export default defineComponent({
  name: 'CreateUnitStepOne',
  data () {
    return {
      isLoading: true,
      customName: '',
      buildingId: '',
      unitData: {},
      barriersData: {},
      barrierTitle: '',
      selectDeviceType: 'message.dashboard.device_type',
      selectNameDevice: 'Name',
      barrierDescription: '',
      errorUnit: null,
      isModalOpen: false,
      isPreview: '@/assets/image/barrier.png',
      errorBarrier: null,
      isPreviewVideo: false,
      dataDeviceType: {},
      dataNameDevice: [],
      deviceTypes: '',
      barrierTypeSelected: 'smart',
      file: null,
      imagePreview: '',
      openPopupDeleteBarrier: false,
      barrierId: null,
      updateBarrier: false,
      barrierUpdateTypeSelected: true
    }
  },
  watch: {
    customName: {
      deep: true,
      handler () {
        if (this.errorUnit) {
          this.errorUnit.name = null
        }
      },
      immediate: true
    },
    barrierTypeSelected () {
      if (this.barrierUpdateTypeSelected) {
        this.barrierTitle = ''
        this.selectDeviceType = 'message.dashboard.device_type'
        this.selectedDeviceType = ''
        this.barrierDescription = ''
        this.file = null
        this.imagePreview = require('@/assets/image/barrier.png')
        this.deviceId = ''
        this.errorBarrier = null
        this.dataNameDevice = ''
        this.deviceTypes = ''
      }
    }
  },
  mounted () {
    this.buildingId = this.$route.query.buildingId
    if (this.$route.query.unitId) {
      this.unitId = this.$route.query.unitId
    }
    this.сreateUnit()
  },
  components: {
    DefaultPopup,
    BarriersCard,
    AlertBlock,
    Field,
    Form,
    ErrorMessage,
    Info,
    Select,
    // AddBarrierPopup,
    LottieAnimation: Vue3Lottie
  },
  methods: {
    getUnit () {
      this.$store.dispatch('property/getUnit', this.unitId).then(
        (res) => {
          this.unitData = res.data
          if (this.unitData?.draft?.name !== null) {
            this.customName = this.unitData?.draft?.name
          }
          this.getDeviceTypes()
          this.getBarriers()
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    сreateUnit () {
      if (this.$route.query.unitId) {
        this.unitId = this.$route.query.unitId
        this.draftUnit()
      } else {
        this.$store.dispatch('property/сreateUnit', this.buildingId).then(
          (res) => {
            this.unitId = res.data.id
            this.$router.push(`/dashboard/create-unit-step-one?buildingId=${this.buildingId}&unitId=${this.unitId}`)
            this.draftUnit()
          },
          (error) => {
            this.error = error.response.data.message
          }
        )
      }
    },
    setPropertiesStepOne (data) {
      this.unitId = this.$route.query.unitId
      data = {
        unitId: this.unitId,
        name: data.custom_name
      }
      this.$store.dispatch('property/setPropertiesStepOne', data).then(
        () => {
          this.$router.push(`/dashboard/create-unit-step-two?buildingId=${this.buildingId}&unitId=${this.unitId}`)
        },
        (error) => {
          this.errorUnit = error.response.data.errors
        }
      )
    },
    draftUnit () {
      this.$store.dispatch('property/draftUnit', this.unitId).then(
        (res) => {
          this.unitId = res.data.id
          this.customProperty = res.data.draft?.custom_name
          this.getUnit()
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    handleDiscard () {
      this.$store.dispatch('property/discardUnit', this.unitId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorUnit = error.response.data.errors
        }
      )
    },
    handleOpenModal (id) {
      this.deleteListingId = id
      this.barrierId = id
      this.isModalOpen = true
      this.barrierUpdateTypeSelected = true
      const data = {
        buildingId: this.unitId,
        barrierId: this.barrierId
      }
      if (typeof this.barrierId === 'number') {
        this.getUnitBarrier(data)
      }
    },
    handleCloseModal () {
      this.isModalOpen = false
      this.barrierTitle = ''
      this.barrierDescription = ''
      this.deviceTypes = ''
      this.errorBarrier = null
      this.file = null
      this.isPreview = '@/assets/image/barrier.png'
      this.isPreviewVideo = false
    },
    createBarrier (data) {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.file) {
        formData.append('attachment', this.file)
      }
      formData.append('title', data.barrier_title)
      formData.append('type', this.barrierTypeSelected)
      formData.append('description', data.description)
      if (this.deviceId) {
        formData.append('device_id', this.deviceId)
      }
      if (this.updateBarrier === false) {
        this.axios.post(API_URL + 'provider/units/' + this.unitId + '/barriers', formData, { headers: authHeader() }).then(() => {
          this.isModalOpen = false
          this.barrierTitle = ''
          this.barrierDescription = ''
          this.selectedDeviceType = ''
          this.isPreview = '@/assets/image/barrier.png'
          this.isPreviewVideo = false
          this.getBarriers()
          this.errorBarrier = null
          this.isLoading = false
          this.file = null
          this.imagePreview = require('@/assets/image/barrier.png')
        },
        (error) => {
          this.errorBarrier = error.response.data.errors
          this.isLoading = false
        }
        )
      } else {
        this.axios.post(API_URL + 'provider/units/' + this.unitId + '/barriers/' + this.barrierId + '/update', formData, { headers: authHeader() }).then(() => {
          this.isModalOpen = false
          this.barrierTitle = ''
          this.barrierDescription = ''
          this.selectedDeviceType = ''
          this.isPreview = '@/assets/image/barrier.png'
          this.isPreviewVideo = false
          this.getBarriers()
          this.errorBarrier = null
          this.isLoading = false
          this.file = null
          this.imagePreview = require('@/assets/image/barrier.png')
        },
        (error) => {
          this.errorBarrier = error.response.data.errors
          this.isLoading = false
        }
        )
      }
    },
    getBarriers () {
      this.$store.dispatch('barriers/getUnitBarriers', this.unitId).then(
        (res) => {
          this.barriersData = res
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.file)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
          this.isPreview = false
          this.isPreviewVideo = false
        }.bind(this),
        false
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
        if (this.file.type === 'video/mp4' || this.file.type === 'video/quicktime') {
          this.isPreview = false
          this.isPreviewVideo = true
          this.imagePreview = null
        }
      }
    },
    getDeviceTypes () {
      this.$store.dispatch('barriers/getDeviceTypes').then(
        (res) => {
          this.dataDeviceType = res
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    handleOptionDeviceTypeSelected (selectedOption) {
      console.log(selectedOption, 'selectedOption')
      this.deviceTypes = selectedOption
      if (this.errorBarrier) {
        this.errorBarrier.device_id = null
      }
      this.getDevicesNames()
    },
    getDevicesNames (query) {
      query = '?filter[manufacture]=' + this.deviceTypes.value + '&filter[usage]=false'
      this.$store.dispatch('devices/getDevices', query).then(
        (res) => {
          this.dataNameDevice = res.data
        },
        (error) => {
          this.errorDevices = error.response
        }
      )
    },
    deletePhoto () {
      this.imagePreview = '@/assets/image/barrier.png'
      this.isPreview = true
      this.isPreviewVideo = false
      this.file = null
    },
    handleOptionNameDeviceSelected (selectedOption) {
      if (this.errorBarrier) {
        this.errorBarrier.device_id = null
      }
      this.deviceId = selectedOption.id
    },
    openDeleteBarrier (id) {
      this.openPopupDeleteBarrier = true
      this.barrierId = id
    },
    handleCloseModalDeleteBarrier () {
      this.openPopupDeleteBarrier = false
    },
    deleteBarrier () {
      this.isLoading = true
      const ids = {
        unitId: this.unitId,
        barrierId: this.barrierId
      }
      this.$store.dispatch('barriers/deleteUnitBarrier', ids).then(
        () => {
          this.getBarriers()
          this.openPopupDeleteBarrier = false
          this.isLoading = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    getUnitBarrier (data) {
      data = {
        buildingId: this.unitId,
        barrierId: this.barrierId
      }
      this.$store.dispatch('barriers/getUnitBarrier', data).then(
        (res) => {
          this.barrierUpdateTypeSelected = false
          this.barrierTypeSelected = res.type.value
          this.barrierTitle = res.title
          if (this.barrierTitle) {
            this.updateBarrier = true
          }
          this.selectDeviceType = res.device?.manufacture.title
          this.deviceTypes = res.device?.manufacture.value
          this.barrierDescription = res.description
          if (res.attachment) {
            this.imagePreview = res.attachment.url
            this.isPreview = false
            this.isPreviewVideo = false
          }
          if (res.device) {
            this.deviceId = res.device.id
            this.dataNameDevice = res.device.name
            this.selectNameDevice = res.device.name
          }
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    deleteUnit () {
      this.$store.dispatch('property/deleteUnit', this.unitId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    }
  }
})
