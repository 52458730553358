<template>
  <div class="custom-select">
    <label
      for=""
      v-if="selectedOption"
    >{{ selectName }}</label>
    <label
      for=""
      v-if="selectedOption !== placeholderLabel"
    >{{ placeholderLabel }}</label>
    <v-select
      :label="title"
      :options="data"
      :placeholder="selectName"
      @click="openSelect"
      v-model="selectedOption"
      :disabled="disabled"
    >
    </v-select>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import vClickOutside from 'click-outside-vue3'
import 'vue-select/dist/vue-select.css'

export default defineComponent({
  name: 'CustomSelect',
  components: {},
  data () {
    return {
      selectOpened: false,
      openSvg: false,
      selectedOption: null
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    data: Array,
    selectName: [String, Number],
    title: String,
    placeholderLabel: [String, Number],
    disabled: Boolean
  },
  methods: {
    openSelect () {
      if (this.selectOpened === true) {
        this.selectOpened = false
        this.openSvg = false
      } else {
        this.selectOpened = true
        this.openSvg = true
      }
    },
    closeSelect () {
      this.selectOpened = false
      this.openSvg = false
    }
  },
  watch: {
    selectedOption (newVal) {
      console.log(newVal, 'fdsfsdf')
      this.$emit('optionSelected', newVal)
    }
  }
})
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 400px;

  label {
    width: 100%;
    display: block;
    text-align: left;
    padding: 0 0 0 2px;
    font-weight: 500;
    font-size: 12px;
    color: #8c8c8c;
    position: absolute;
    bottom: 25px;
  }

  ::v-deep(.vs__dropdown-toggle) {
    border: none;
    border-bottom: 1px solid #d2d4d8;
  }
  ::v-deep(.vs__search) {
    padding: 0;
    margin: 0;
    position: relative;
    top: 3px;
  }
  ::v-deep(.vs__search::placeholder) {
    color: #8c8c8c;
    font-weight: 500;
    font-size: 18px;
  }
  ::v-deep(.vs__clear) {
    display: none;
  }
  ::v-deep(.vs__dropdown-toggle) {
    padding: 0 0 2px;
  }
  ::v-deep(.vs__actions svg) {
    fill: none;
    border: none;
    background-image: url('../../assets/image/select-arrow.png');
    background-repeat: no-repeat;
    width: 14px;
    height: 9px;
    background-size: contain;
  }
  ::v-deep(.vs__selected) {
    padding: 0;
    margin: 0;
    color: #000000;
    font-size: 18px;
    position: relative;
    top: 3px;
    left: 1px;
  }
  ::v-deep(.vs__dropdown-option--highlight) {
    background: #eff8eb !important;
    color: #000000 !important;
  }
  ::v-deep(.vs__dropdown-menu) {
    padding: 0 !important;
  }
  ::v-deep(.vs__selected-options) {
    padding: 0 !important;
  }
  ::v-deep(.vs__dropdown-option--selected) {
    background-image: url("../../assets/image/arrow-selected.png");
    background-repeat: no-repeat;
    background-position: 5px 10px;
  }
}

@media (max-width: $break-sm) {
  .custom-select {
  }
}
</style>
